<template>
<div>
  <div class="overflow-x-hidden relative rounded-md">
    <!-- Border -->
     <div class="absolute top-0 left-0 w-full rounded-md border border-border-normal z-10 pointer-events-none select-none" style="height: calc(100% - 52px);"/>
    <!-- Query loader -->
    <div v-if="loading" class="bg-gray-100 opacity-50 rounded-md absolute top-0 left-0 w-full h-full z-50 flex justify-center items-center">
      <BaseLoadingSpinnerCircle class="text-icon-normal w-8 h-8"/>
    </div>
    <div class="w-full overflow-x-auto scroll-container">
      <table class="relative">
        <thead>
          <tr> 
            <!-- Grouped by column -->
            <th class="sticky-column">
              <div class="group-cell border-r flex items-center px-2 py-3 h-10 gap-3 ">
                <input
                  type="checkbox"
                  class="checkbox-input"
                  :disabled="disableToggleAll"
                  :checked="!disableToggleAll && preventRowAllAdd"
                  @change="toggleAllRows"
                >
                <BaseText
                  type="heading"
                  size="overline"
                  class="text-text-subdued uppercase"
                >
                  {{ isAdLevel ? 'Ad Name' : groupedBy?.replace(/_/g, ' ') }}
                </BaseText>
                <BaseText v-if="selectedRows.length > 0" type="heading" size="overline" class="text-text-disabled">({{ selectedRows.length }} selected)</BaseText>
              </div>
            </th>
            <!-- Other selected kpis -->
            <th
              v-for="header in tableKpis"
              :key="header.key"
              :class="{ 'sticky-column': header.is_pinned, 'header-column': true }"
              :style="header.is_pinned ? { left: `${calculatePinOffset(header)}px` } : {}"
            >
            <HeaderActionCell 
              :disableActions="header.key === 'status' || header.key === 'tags'"
              :header="header"
              :isSelected="selectedColumns.includes(header.key)"
              :columnColor="graphInfo.selectedGraph === 'bar' ? getColumnColor(header) : ''"
              :canSelectCol="!preventColAdd"
              :currentSort="currentSort"
              :isAdLevel="isAdLevel"
              :isMetadata="header.is_metadata"
              @pin="pinColumn" 
              @sort="sortColumn" 
              @select="selectColumn" 
            />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in rows"
            :key="`${row}-${index}`"
          >
            <td
              class="sticky-column table-cell"
            >
              <div class="group-cell border-r grid grid-cols-12 py-1 px-2 gap-x-3 items-center">
                <!-- Left checkbox -->
                <div class="col-span-1">
                  <input
                    :ref="`checkbox-${row.group_by}`"
                    :checked="isRowSelected(row.group_by || row.ad_id)"
                    class="checkbox-input"
                    :style="{ backgroundColor: isRowSelected(row.group_by || row.ad_id) ? getRowCheckboxColor(row.group_by) : '' }"
                    type="checkbox"
                    :disabled="(!isRowSelected(row.group_by || row.ad_id) && preventRowAdd) || !row.group_by"
                    @change="selectRow(row)"
                    @mouseenter="showTooltip('checkboxInclude', true, $event, isRowSelected(row.group_by || row.ad_id) ? 'Remove from visualization': 'Include in visualization')"
                    @mouseleave="showTooltip('checkboxInclude', false, $event)"
                  >
                </div>
                <!-- Middle info -->
                <div class="ml-1" :class="isAdLevel ? 'col-span-9' : 'col-span-8'">
                    <div class="flex items-center flex-nowrap gap-2 min-w-0" :class="{'py-1': isAdLevel}">
                      <!-- Group image -->
                      <div class="flex-shrink-0" v-if="row?.ad_image_url || row.ads.length > 0">
                        <img
                          :src="isAdLevel ? row.ad_image_url : row.ads?.[0].ad_image_url"
                          class="rounded-md w-7 h-7  bg-cover"
                        >
                      </div>
                      <!-- Group title & ad count -->
                      <div class="flex-grow min-w-0 whitespace-nowrap relative">
                      <div
                        @mouseenter="showTooltip('groupTooltip', true, $event, uniqueAds(row.ads))"
                        @mouseleave="showTooltip('groupTooltip', false, $event)" 
                      >
                        <BaseText
                        v-clipboard:copy="row.group_by || row.ad_name"
                        v-clipboard:success="handleCopySuccess"
                        class="text-text-muted truncate cursor-pointer max-w-min"
                        size="sm"
                  
                        >
                        {{ isAdLevel ? row.ad_name : row.group_by }}
                      </BaseText>
                    </div>
                      <BaseText
                        v-if="!isAdLevel"
                        class="text-text-normal"
                        size="xs"
                      >
                        {{ row.ads.length }} Ads
                      </BaseText>
                    </div>
                  </div>
                </div>
                <!-- CTA actions -->
                <div :class="{'col-span-2': !isAdLevel}">
                  <div class="flex items-center min-w-0">
                    <button class="p-1" @mouseenter="hoveredInsightsBtnId = row.group_by || row.ad_id" @mouseleave="hoveredInsightsBtnId = null">
                      <AdDetailsAnimatedIcon class="cursor-pointer text-icon-normal" :isHovered="hoveredInsightsBtnId === (row.group_by || row.ad_id)"/>
                    </button>
                    <button v-if="!isAdLevel" class="p-1" @mouseenter="hoveredDetailsBtnId = row.group_by" @mouseleave="hoveredDetailsBtnId = null"
                    @click="() => $emit('showGroupBreakdown', row)">
                      <GroupDetailsIcon class="cursor-pointer text-icon-normal" :isHovered="hoveredDetailsBtnId === row.group_by"/>
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <!-- Selected KPI Columns -->
            <td
              v-for="(kpi, colIndex) in tableKpis"
              :key="`${kpi}-${colIndex}`"
              :ref="`td-${kpi.key}`"
              class="table-cell "
              :class="{'sticky-column' : kpi.is_pinned}"
              :style="[
                kpi.is_pinned ? {left:`${calculatePinOffset(kpi)}px`} : null, 
                row?.[kpi.key] !== 0 ? {backgroundColor: getColoredCell(row,kpi).scoreColor} : null,
                { maxWidth: kpi?.is_metadata ? '222px' : null }
              ]"
            >
              <div class="cell relative">
                <!-- Handle status & tag cells -->
                <StatusCell
                  class="px-3 py-2.5"
                  v-if="kpi.key === 'status' && tableConfig?.showStatus"
                  :data="{ 
                    value: isAdLevel 
                      ? { state: row.ad_status }
                      : { active: row.active_ad_count, inactive: row.inactive_ad_count, paused: row.paused_ad_count } 
                  }"
                  :isAdLevel="isAdLevel"
                />
                <TagCell class="px-3 py-1.5" v-else-if="kpi.key === 'tags' && tableConfig?.showTags"/>
                <TableCell
                  v-else
                  class="p-3"
                  :color-format="getColoredCell(row,kpi).direction"
                  :metric-direction="getMetricLookup?.[kpi.key]?.metric_direction"
                  :avg-diff="row?.percent_deviation?.[kpi.key]"
                  :cell-type="getMetricLookup?.[kpi.key]?.type || 'text'"
                  :cell-value="row?.[kpi.key]"
                  :is-metadata="kpi?.is_metadata"
                  :is-url="kpi.key === 'destination_url'"
                />
              </div>
            </td>
          </tr>
        </tbody>
        <!-- Table footer (Net results across all groupings) -->
        <tfoot>
          <tr>
            <td class="sticky-column">
              <div class="group-cell py-3 px-1.5 bg-background-disabled h-full flex gap-3 items-center">
                <div class="relative group" @click="navToNetInfo"
                  @mouseenter="showTooltip('checkboxInclude', true, $event, 'Click here to learn more')"
                  @mouseleave="showTooltip('checkboxInclude', false, $event)"
                >
                  <InformationIcon  class="text-icon-disabled group-hover:text-icon-normal cursor-pointer" />
                </div>
                <div>
                  <BaseText
                  size="sm"
                  type="label"
                  class="text-text-muted col-span-10"
                  
                  >
                  Net Results
                </BaseText>
              </div>
            </div>
            </td>
            <td
              v-for="(kpi,kpiIndex) in tableKpis"
              :key="`net-${kpi}-${kpiIndex}`"
              :class="{ 'sticky-column': kpi.is_pinned }"
              :style="kpi.is_pinned ? { left: `${calculatePinOffset(kpi)}px` } : ''"
            >
              <div v-if="!kpi.is_metadata" class="cell p-3 flex items-center gap-3 bg-background-disabled">
                <TableCell
                  v-if="kpi.key !== 'status'"
                  :cell-type="getMetricLookup?.[kpi.key]?.type"
                  :cell-value="netResults?.[kpi.key]"
                />
                <!-- Average -->
                <BaseText
                  v-else-if="kpi.key === 'status'"
                  class="text-text-muted"
                  style="max-width: 132px"
                  size="sm"
                >
                  <div class="truncate" v-if="kpi.key === 'status' && tableConfig?.showStatus">
                    {{ netResults.active_ad_count }} Active, {{ netResults.paused_ad_count }} Paused, {{ netResults.inactive_ad_count }} Inactive
                  </div>
                  <div
                    v-else
                    class="flex-nowrap flex items-center"
                  >
                    Avg.&nbsp;
                    <TableCell
                      :cell-type="getMetricLookup?.[kpi.key]?.type"
                      :cell-value="netResults.weighted_averages?.[kpi.key]"
                    />
                  </div>
                </BaseText>
              </div>
              <!-- If it's an ad-level metadata column, we display the number of unique values -->
              <div v-else class="cell p-3 bg-background-disabled">
                <BaseText size="sm" class="text-text-muted" style="max-width: 132px">
                  {{ getMetadataNetResultLabel(kpi) }}
                </BaseText>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <TablePagination
      class="mr-12 ml-2 mb-1"
      v-if="tablePagination?.totalPages !== 0"
      :showing="rows.length"
      :tablePagination="tablePagination"
      @updatePagination="$event => $emit('paginationChanged', { type: null, value: $event})"
    />
    <portal-target name="sort-dropdown">
      <!-- Sort dropdown -->
    </portal-target>
  </div>
  <!-- Modals & Global tooltips -->
  <ColumnTooltips ref="tooltipContainer" :graphInfo="graphInfo"/>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import kpiColors from '../../../utils/lens/selectedMetricColors'
import {getRelativeMetricScoreColor} from "../../../utils/lens/metricScoreUtil"
import ColumnTooltips from './ColumnTooltips.vue'
// Table cell components
import TablePagination from './TablePagination.vue'
import TableCell from './cells/TableCell.vue'
import StatusCell from './cells/StatusCell.vue'
import TagCell from './cells/TagCell.vue'
import HeaderActionCell from './cells/HeaderActionCell.vue'

// Icons
import AdDetailsAnimatedIcon from '../../globals/Icons/AdDetailsAnimatedIcon.vue'
import GroupDetailsIcon from '../../globals/Icons/LensIcons/GroupDetailsAnimatedIcon.vue'
import SortColumnIcon from '../../globals/Icons/SortColumnIcon.vue'
import CheckmarkIcon from '../../globals/Icons/CheckmarkIcon.vue'
import PinIcon from '../../globals/Icons/SpyderV2Icons/PinIcon.vue'
import InformationIcon from '../../globals/Icons/InformationIcon.vue'
import BarGraphIcon from "../../globals/Icons/LensIcons/graphs/BarGraphIcon.vue"

export default {
  name: 'LensMetricTable',
  components: {
    TablePagination,
    TableCell,
    StatusCell,
    TagCell,
    HeaderActionCell,

    ColumnTooltips,

    AdDetailsAnimatedIcon,
    GroupDetailsIcon,
    InformationIcon,
    SortColumnIcon,
    CheckmarkIcon,
    BarGraphIcon,
    PinIcon
  },
  props: {
    isAdLevel: {
      type: Boolean,
      default: () => false
    },
    groupedBy: {
      type: String,
      default: () => 'ad_name'
    },
    tablePagination: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {}
    },
    selectedColumns: {
      type: Array,
      default: () => []
    },
    selectedRows: {
      type: Array,
      default: () => []
    },
    tableColumns: {
      type: Array,
      default: () => []
    },
    tableConfig: {
      type: Object,
      default: () => {}
    },
    currentSort: {
      type: Object,
      default: () => {}
    },
    maxRowCount: {
      type: Number,
      default: () => 8
    },
    graphInfo: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    
    const disabledAdLevelTooltips = ['groupTooltip']
    return {
      hoveredCell: {},
      disabledAdLevelTooltips,
      hoveredInsightsBtnId: null,
      hoveredDetailsBtnId: null
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getAppliedPreset', 'getMappedRowColor']),
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    rows () {
      if (this.isAdLevel && this.data?.ads?.length > 0) {
        return this.data.ads
      } else if (this.data?.ad_groups?.length > 0) {
        return this.data.ad_groups
      }
      return []
    },
    netResults () {
      if (this.isAdLevel || this.data?.ad_groups?.length > 0) {
        return { ...this.data }
      }
      return {}
    },
    pinnedColumns () {
      return this.tableColumns.filter((col) => col.is_pinned);
    },
    tableKpis () {
      const showStatus = this.tableConfig?.showStatus
      const showTags = this.tableConfig?.showTags

      if (this.pinnedColumns.length > 0) {
        return [
          ...(showStatus ? [{key: 'status'}] : []),
          // ...(showTags ? [{key: 'tags'}] : []),
          ...[...this.tableColumns].sort((a, b) => {
            const indexA = this.pinnedColumns.indexOf(a)
            const indexB = this.pinnedColumns.indexOf(b)
            if (indexA === -1 && indexB === -1) return 0
            if (indexA === -1) return 1
            if (indexB === -1) return -1
            return indexA - indexB
          })
        ]
      }

      return [
        ...(showStatus ? [{key:'status'}] : []),
        // ...(showTags ? [{key: 'tags'}] : []),
        ...this.tableColumns
      ]
    },
    preventColAdd () {
      const currentGraph = this.graphInfo.selectedGraph
      const maxAllowed = this.graphInfo.data[currentGraph].total
      return this.selectedColumns?.length === maxAllowed
    },
    preventRowAdd () {
      return this.selectedRows?.length === Math.min(this.maxRowCount, this.tablePagination.limit)
    },
    checkboxStates(){
      return this.rows.reduce(
        (acc, row) => {
          const isSelected = this.selectedRows.some(selectedRow => selectedRow.group_by === row.group_by);
          if (isSelected) {
            acc.checkedRows.push(row.group_by);
          } else {
            acc.unCheckedRows.push(row);
          }
          return acc;
        },
        { checkedRows: [], unCheckedRows: [] }
      );
    },
    disableToggleAll(){
      return this.preventRowAllAdd && this.checkboxStates.checkedRows.length === 0
    },
    selectedRowCount(){
      return this.selectedRows.length;
    },
    preventRowAllAdd(){
      return (this.maxRowCount - this.selectedRowCount) === 0
    }
  },
  methods: {
    ...mapActions('LensModule', ['updateLocalPresetField', 'updateRowColor']),
    showTooltip(refName, bool, event, text){
      if (this.isAdLevel && this.disabledAdLevelTooltips.includes(refName)) return;

      const tooltipContainer = this.$refs.tooltipContainer;
      const includeTooltip = tooltipContainer.$refs[refName];
      if (includeTooltip && !bool){
        includeTooltip.style.transition = 'opacity 0s ease 0ms';
        includeTooltip.style.opacity = '0'
      }
      else{
        if (text) tooltipContainer.updateTextCopy(refName, text);
        this.$nextTick(() => {
          const tooltipRect = includeTooltip.getBoundingClientRect()
          const referenceRect = event.target.getBoundingClientRect()
          // Calculate positions with offsets (in drawers, we need to offset the fixed position) TODO @sam: make it more versatile
          const offset = this.isAdLevel ? { top: -74, left: 56 } : { top: 8, left: 0 }
          const position = {
            top: referenceRect.top - tooltipRect.height - 12 + offset.top,
            left: referenceRect.left + (referenceRect.width / 2) - (tooltipRect.width / 2) + offset.left
          }
        includeTooltip.style.top = `${position.top}px`
        includeTooltip.style.left = `${position.left}px`
        // Add delay param later
        includeTooltip.style.transition = 'opacity 150ms ease 350ms';
        includeTooltip.style.opacity = '1'
      })
      }
    },
    handleCopySuccess () {
      this.$showAlert({
        type: 'success',
        message: `Copied ${this.groupedBy?.replace(/_/g, ' ')}`
      })
    },
    uniqueAds (ads) {
      if (ads && ads.length > 0){
      const adLimit = 20;
      const limitedAds = _.uniq(ads).slice(0, adLimit).map((ad) => ad?.[this.groupedBy]);
      if (ads.length > adLimit) {
        return [...limitedAds, `+${ads.length - adLimit} more`];
      }
      return limitedAds;
      }
      return [];
    },
    // memoize the color format for a given value & kpi & color format
    showColoredCell: _.memoize(function (percentDiff, kpi, outlierFormat) {
      const summary = this.data?.summary_stats;
      return getRelativeMetricScoreColor(percentDiff,kpi, outlierFormat, summary)
    }, (arg1, arg2, arg3) => `${arg1}-${arg2}-${arg3}`),
    getColoredCell (row,kpi) {
      if (this.isAdLevel) return ''; // TODO: We might want deviation highlighting for ad level later

      const key = kpi.key;
      // TODO @Sam, maybe use memoized func directly, and add dependency callback as floored value to prevent new calcs
      const percentDiff = Math.floor(row.percent_deviation?.[key])
      return this.showColoredCell(percentDiff, key, this.tableConfig?.colorFormat);
    },
    getRowCheckboxColor(groupBy) {
      const currentGraph = this.graphInfo.selectedGraph
      if (this.isAdLevel || currentGraph === 'grid') return '#000'
      return this.getMappedRowColor[currentGraph][groupBy]
    },
    manageArrayAdd (arr, toAdd) {
      const exists = arr.includes(toAdd)
      return exists ? arr.filter((val) => val !== toAdd) : [...arr, toAdd]
    },
    // Column actions
    pinColumn (header) {
      // const newStickyColumns = this.manageArrayAdd(this.pinnedColumns, header)
      const newTableCols = [...this.tableColumns].map(col => {
        if (col.key === header.key) {
          return { ...col, is_pinned: !col.is_pinned };
        }
        return col;
      });
      this.$emit('reportChanged', { type: 'table_columns', value: newTableCols, preventFetch: true })
    },
    sortColumn (column, order) {
      const colKey = column.key

      const newSort = {
        by: colKey,
        order
      }
      const samekey = newSort.by === this.currentSort?.by;
      const sameOrder = newSort.order === this.currentSort?.order;
      if (samekey && sameOrder) return;
      // Prevent fetch on report change, as pagination will trigger a requery
      this.$emit('reportChanged', { type: 'sorted_column', value: newSort})
      this.$emit('paginationChanged', { type: null, value: {currentPage: 1}, preventFetch: true})
    },
    selectColumn (column) {
      const colKey = column.key
      const updatedColumns = this.manageArrayAdd(this.selectedColumns, colKey)
      const existingCols = this.tableColumns.filter((col) => updatedColumns.includes(col.key)).map((col) => col.key)
      this.$emit('update:selectedColumns', existingCols)
    },
    selectRow (row) {
      const idKey = this.isAdLevel ? 'ad_id' : 'group_by';
      const rowId = row[idKey];

      const shouldRemoveRow =  this.selectedRows.some(selectedRow => selectedRow[idKey] === rowId)
      const updatedRows = shouldRemoveRow
        ? this.selectedRows.filter(selectedRow => selectedRow[idKey] !== rowId)
        : [...this.selectedRows, row];

      this.updateRowColor({keys: [row.group_by], isAdding: !shouldRemoveRow, graph: this.graphInfo.selectedGraph})
      this.$emit('update:selectedRows', updatedRows)
    },
    isRowSelected (id) {
      if (!this.selectedRows || this.selectedRows.length == 0) return;
      return this.selectedRows.findIndex((row) => this.isAdLevel ? row.ad_id === id : row.group_by === id) !== -1;
    },
    // TODO @Sam: clean up
    toggleAllRows () {
      // Left over # of rows we can select
      const rowsLeftToAdd = this.maxRowCount - this.selectedRowCount;
      const unCheckedRows = this.checkboxStates.unCheckedRows;
      const checkedRows = this.checkboxStates.checkedRows;

      const keysToUpdate = this.preventRowAllAdd ? this.selectedRows.filter((row) => !checkedRows.includes(row.group_by)) :  [...this.selectedRows, ...unCheckedRows.slice(0, rowsLeftToAdd)]
      const keysToColor = this.preventRowAllAdd ? this.selectedRows.filter((row) => !checkedRows.includes(row.group_by)) : [...this.selectedRows, ...unCheckedRows.slice(0, rowsLeftToAdd)]
      
      this.$emit('update:selectedRows', keysToUpdate)
      this.updateRowColor({keys: keysToColor, isAdding: !this.preventRowAllAdd, graph: this.graphInfo.selectedGraph })
    },
    calculatePinOffset (kpi) {
      const key = kpi.key;
      // To incrementally clip the next pinned column
      const index = this.pinnedColumns.findIndex((col) => col.key === key)
      if (index === 0) return 320

      let currentColumns = [...this.pinnedColumns]
      currentColumns = currentColumns.slice(0, index)
      return currentColumns.reduce((acc, column) => {
        const columnRef = this.$refs[`td-${column.key}`]?.[0]
        return acc + Math.ceil(columnRef?.offsetWidth || 0)
      }, 320)
    },
    getColumnColor (header) {
      const key = header.key;
      const cIndex = this.selectedColumns.findIndex((col) => col === key);

      return kpiColors[cIndex];
    },
    navToNetInfo () {
      console.log('calle')
      // TODO @Sam: update with knowledge base
      window.open("https://www.foreplay.co/", "_blank")
    },
    getMetadataNetResultLabel (col) {
      if (!col.is_metadata) return ''
      const numUnique = col.unique_values
      const name = col.name.replace(/\bname\b/gi, '').trim()
      return `${numUnique} ${name}${numUnique === 1 ? '' : 's'}`
    }
  }
}
</script>

<style scoped>
.scroll-container::-webkit-scrollbar {
  height: 16px; 
}

.scroll-container::-webkit-scrollbar-track {
  background: #ffffff;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(236, 239, 243, 1);
  border-radius: 10px;
  border: 5px solid #ffffff;
  cursor: pointer;
  transition: border-color 150ms ease-in-out, border-width 150ms ease-in-out;  
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(223, 225, 231, 1);
  border-width: 4px;
}

.scroll-container {
  transition: all 150ms;
  scroll-behavior: smooth;
}

/* Table Styles */
table {
  border-collapse: separate;
  border-spacing: 0px;
}

th,
td {
  background-color: white;
  border: none;
  margin: 0;
  padding: 0px;
}

/* Border Styles (rounded corners & bordered cells) */
table tr th:first-child .group-cell {
  border-top-left-radius: 6px;
  /* border-left: 1px solid #ECEFF3;  */
  border-bottom: 1px solid #ECEFF3; 
  /* border: 1px solid #ECEFF3; */
}

table tr th:last-child .cell-header:last-child {
  border-right: 1px solid #ECEFF3;
  border-top: 1px solid #ECEFF3;
  border-bottom: 1px solid #ECEFF3;
  /* border-top-right-radius: 6px; */
}

table th:not(:first-child):not(:last-child) .cell-header {
  border-right: 1px solid #ECEFF3;
  border-top: 1px solid #ECEFF3;
  border-bottom: 1px solid #ECEFF3;
}

table tr td .cell {
  border-bottom: 1px solid #ECEFF3;
} 

table tr td.sticky-column .cell, table tfoot tr td .cell, table tr td:last-child .cell{
  border-right: 1px solid #eceff3; 
}

/* table tr td:not(first-child):not(last-child) .group-cell {
  border-left: 1px solid #eceff3;
  border-right: 1px solid #eceff3; 
  border: 2px solid red

}  */


table tfoot tr td:last-child .cell {
  /* border-bottom-right-radius: 6px; */
}

table tfoot tr td .group-cell {
  /* border-bottom-left-radius: 6px; */
}

.table-cell > .group-cell{
  border-bottom: 1px solid #eceff3;
}


.table-cell {
  transition: colors 50ms ease-in-out;
  z-index: 2;
}


.cell {
  min-width: 132px;
  max-width: 100%;
}




/* Base cell highlight style */
.cell-highlight{
  position: absolute;
  top: -1px;
  left: 0px;
  height: calc(100% + 1px);
  width: calc(100% + 1px);
  z-index: 2;
  pointer-events: none;
}

.cell-red >>> .table-cell-value{
  color: #710E21
}

.cell-green >>> .table-cell-value{
  color: #184E44
}

.cell-green-lowAvg{
  background-color: #EFFEFA !important;
}

.cell-green-midAvg {
  background-color: #D8F3EE !important;
}

.cell-green-highAvg {
  background-color: #B9E9E0 !important;
}

.cell-red-lowAvg{
  background-color: #FFF0F3 !important;
}

.cell-red-midAvg{
  background-color: #FDD9E0 !important;
}

.cell-red-highAvg{
  background-color: #F9C8D2 !important;
}

.group-cell {
  border-color: #ECEFF3;
  width: 320px;
}


.sticky-column {
  position: sticky;
  left: 0;
  z-index: 3;
}

.column-actions>div {
  transition: opacity 150ms ease-in-out;
}

.truncate-group-tooltip{
  background: #24252D;
  bottom: calc(100% - 12px);
  opacity: 0;
  z-index: 999 !important;
  transition: all 150ms;
  pointer-events:none;

  left: 0;
  transform: translateY(0);
}

.truncate-group-trigger:hover + .truncate-group-tooltip {
  transform: translateY(-14px) !important;
  opacity: 1 !important;
}

/*
  Checkbox Styles
*/
.checkbox-input {
  box-shadow: 0px 0px 0px 1px #09194821, 0px 1px 2px 0px #09194821;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
}

.checkbox-input:disabled {
  background-color: #ECEFF3;
  cursor: not-allowed;
}

.checkbox-input:checked {
  background-color: black;
}

table tr:hover > td {
  background-color: #fcfcfc;
}
</style>
