<template>
  <div class="p-2 flex items-center justify-between w-full gap-1">
    <div class="flex items-center gap-1 relative">
      <button
        class="p-1 pr-2 hover:bg-background-normal truncate-group-trigger rounded-md flex items-center gap-1"
        @click="navToLens"
      >
        <ChevronIcon class="transform rotate-90" />
        <div class="w-8 h-8 rounded-md flex items-center justify-center">
          <img
            v-if="lens && lens.avatar"
            :src="lens.avatar"
            class="w-full h-full object-cover rounded-md"
          >
          <div
            v-else
            class="w-full h-full flex items-center justify-center rounded-md"
            :class="{'bg-neutral-400': !loading && lens.name}"
          >
            <span
              v-if="!loading && lens.name"
              class="text-background-hover text-label-xs font-medium"
            >
              {{ getInitials(lens.name) }}
            </span>
            <div
              v-else-if="loading"
              class="w-full h-full skeleton rounded-md"
            />
          </div>
        </div>
      </button>
      <div
        v-if="lens?.name"
        class="truncate-group-tooltip transform px-1.5 py-1 rounded-md absolute shadow-md space-y-1"
      >
        <BaseText
          class="text-white"
          size="xs"
        >
          {{ lens.name }}
        </BaseText>
      </div>

      <!-- Gap Line -->
      <div class="w-px h-5 bg-border-normal rounded-sm mx-1" />

      <div class="flex items-center gap-2">
        <div
          v-if="loading"
          class="h-5 w-16 skeleton rounded-md"
        />
        <BaseDropdownRouteSwitcher
          v-else
          :current-route="currentRoute"
          :routes="routes"
        />
        <div
          v-if="loading"
          class="h-5 w-40 skeleton rounded-md"
        />
        <BaseText
          v-else
          class="text-icon-normal fade-in"
          size="sm"
        >
          {{ description }}
        </BaseText>
      </div>
    </div>
    <div class="flex items-center gap-2">
      <div class="py-1.5 px-2">
        <!-- Updated to show time since last update -->
        <div
          class="flex items-center gap-2"
        >
          <RefreshIcon class="text-icon-normal" />
          <div
            v-if="loading"
            class="h-6 w-6 skeleton rounded-md"
          />
          <BaseText
            v-else
            type="label"
            size="sm"
            class="text-text-muted"
          >
            {{ getTimeFromNow(lens?.last_refreshed_at) }}
          </BaseText>
        </div>
      </div>
      <div
        v-on-clickaway="() => { show.attributionWindow = false }"
        class="relative"
      >
        <div
          v-if="loading"
          class="h-8 skeleton rounded-md"
          style="width: 152px"
        />
        <button
          v-else
          class="flex items-center gap-1 base-btn h-8 px-2 py-1.5 rounded-lg fade-in"
          :class="{'expanded': show.attributionWindow}"
          @click="show.attributionWindow = !show.attributionWindow"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            {{ formatAttributionWindow }}
          </BaseText>
          <div
            class="transform transition-transform duration-200"
            :style="show.attributionWindow ? 'transform: scale(1, -1)' : ''"
          >
            <ChevronIcon class-name="text-icon-normal" />
          </div>
        </button>
        <transition name="fade">
          <div
            v-if="show.attributionWindow"
            class="absolute right-0 mt-1 border border-border-normal bg-white shadow-sm rounded-lg "
            style="width: 230px;z-index: 99999;"
          >
            <div class="p-2">
              <div class="w-full text-center bg-background-normal rounded-md py-1.5 px-2.5">
                <BaseText
                  class="text-text-muted"
                  type="label"
                  size="sm"
                >
                  Attribution Window
                </BaseText>
              </div>
              <!-- Window inputs -->
              <div class="flex items-center gap-1 flex-col mt-2">
                <div class="flex w-full items-center justify-between z-20">
                  <BaseText
                    class="text-text-normal"
                    size="sm"
                  >
                    Click
                  </BaseText>
                  <div class="w-24">
                    <BaseSingleDropdown
                      :selected-obj="reportAttributionWindow.click"
                      :options="attributionWindowOptions"
                      option-key="label"
                      placeholder="Click"
                      use-parent-width
                      @change="updateWindow('click', $event)"
                    />
                  </div>
                </div>
                <div class="flex w-full items-center justify-between z-10">
                  <BaseText
                    class="text-text-normal"
                    size="sm"
                  >
                    View
                  </BaseText>
                  <div class="w-24">
                    <BaseSingleDropdown
                      :selected-obj="reportAttributionWindow.view"
                      :options="viewOptions"
                      placeholder="View"
                      option-key="label"
                      use-parent-width
                      @change="updateWindow('view', $event)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- cta -->
            <div class="flex w-full justify-end border-t p-2 border-border-normal">
              <button
                class="text-white black-btn-primary px-3 py-1 rounded-md"
                :disabled="disableWindowUpdate.disable"
                @click="submitNewWindow"
              >
                <BaseText
                  type="label"
                  size="sm"
                >
                  Apply
                </BaseText>
              </button>
            </div>
          </div>
        </transition>
      </div>
      <!-- Share -->
      <button
        class="flex text-white items-center gap-1 black-btn-primary p-1.5 pr-3 rounded-md h-8"
        @click="$emit('showShareReport')"
      >
        <ShareIcon />
        <BaseText
          type="label"
          size="sm"
        >
          Share
        </BaseText>
      </button>
      <!-- Kebab -->

      <div
        v-on-clickaway="() => { show.optionsDropdown = false }"
        class="relative"
      >
        <button
          class="base-btn h-8 hover:bg-background-normal rounded-md transition-colors"
          :class="{'bg-background-normal' : show.optionsDropdown}"
          style="padding: 6px;"
          @click="show.optionsDropdown = !show.optionsDropdown"
        >
          <KebabMenuIcon class="text-icon-normal" />
        </button>

        <transition name="fade">
          <div
            v-if="show.optionsDropdown"
            class="p-1 absolute w-64 right-0 mt-1 border border-border-normal bg-white overflow-y-hidden dropdown-scrollable shadow-md rounded-lg "
            style="z-index: 99999"
          >
            <div
              v-for="opt in reportOptions"
              :key="opt.id"
            >
              <button
                v-if="opt.action"
                class="group cursor-pointer flex items-center gap-2 rounded-lg p-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap w-full"
                :class="{'opacity-50 cursor-not-allowed hover:bg-transparent' : disabledBtns.includes(opt.id)}"
                @click="opt.action"
              >
                <component
                  :is="opt.icon"
                  class="text-icon-normal"
                />
                <BaseText
                  class="text-text-muted"
                  type="label"
                  size="sm"
                >
                  {{ opt.name }}
                </BaseText>
                <BaseText
                  v-if="disabledBtns.includes(opt.id)"
                  size="xs"
                  class="text-text-disabled"
                >
                  (Coming soon)
                </BaseText>
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <BaseConfirmDeleteModal
      v-if="show.deleteModal"
      confirm-text="Delete report"
      delete-disclaimer="Are you sure you want to delete this report? This action cannot be undone."
      :loading="loadingDelete"
      @close="show.deleteModal = false"
      @confirm="deleteReport"
    />
    <EditReportModal
      v-if="show.editModal"
      :report-name="title"
      :report-desc="description"
      :report-id="reportId"
      @close="show.editModal = false"
      @update="updateLocalReport"
    />
  </div>
</template>

<script>
import LensAPI from '@/api/lens'

import { mixin as clickaway } from 'vue-clickaway2'
// Components
import BaseConfirmDeleteModal from '../../globals/BaseConfirmDeleteModal.vue'
import EditReportModal from './EditReportModal.vue'
import BaseDropdownRouteSwitcher from '../../globals/BaseDropdownRouteSwitcher.vue'

// Icons
import KebabMenuIcon from '../../globals/Icons/KebabMenuIcon.vue'
import ShareIcon from '../../globals/Icons/ShareIcon.vue'
import ReportIcon from '../../globals/Icons/LensIcons/ReportIcon.vue'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'
import RefreshIcon from '../../globals/Icons/LensIcons/RefreshIcon.vue'
import ExportCSVIcon from '../../globals/Icons/ExportCSVIcon.vue'
import DeleteIcon from '../../globals/Icons/DeleteIcon.vue'
import DuplicateIcon from '../../globals/Icons/DuplicateIcon.vue'
import EditIcon from '../../globals/Icons/EditIcon.vue'
export default {
  name: 'LensReportHeader',
  components: {
    // Components
    BaseConfirmDeleteModal,
    EditReportModal,
    // Icons
    ChevronIcon,
    RefreshIcon,
    ShareIcon,
    ReportIcon,
    KebabMenuIcon,
    DeleteIcon,
    BaseDropdownRouteSwitcher
  },
  mixins: [clickaway],
  props: {
    reportId: {
      type: String,
      default: () => ''
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => ''
    },
    description: {
      type: String,
      default: () => ''
    },
    attributionWindow: {
      type: Object,
      default: () => {}
    },
    lens: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const disabledBtns = ['export-csv', 'export-pdf']
    const reportOptions = [
      {
        id: 'edit',
        icon: EditIcon,
        name: 'Edit Report',
        action: this.editReport
      },
      {
        id: 'duplicate',
        icon: DuplicateIcon,
        name: 'Copy Report',
        action: this.duplicateReport
      },
      {
        id: 'export-csv',
        icon: ExportCSVIcon,
        name: 'Export CSV',
        action: this.exportCSV
      },
      {
        id: 'export-pdf',
        icon: ExportCSVIcon,
        name: 'Export PDF',
        action: this.exportPDF
      },
      {
        id: 'delete-report',
        icon: DeleteIcon,
        name: 'Delete Report',
        action: this.showDeleteConfirm
      }
    ]

    const attributionWindowOptions = [
      { label: '1 Day', value: '1d' },
      { label: '7 Days', value: '7d' },
      { label: '28 Day', value: '28d' }
    ]

    const viewOptions = [
      { label: '1 Day', value: '1d' },
      { label: '7 Days', value: '7d' },
      { label: '28 Days', value: '28d' }
    ]
    return {
      reportOptions,
      disabledBtns,
      attributionWindowOptions,
      viewOptions,

      loadingDelete: false,

      reportAttributionWindow: {
        click: null,
        view: null
      },
      show: {
        optionsDropdown: false,
        duplicateModal: false,
        deleteModal: false,
        attributionWindow: false,
        editModal: false
      },
      routes: []
    }
  },
  computed: {
    formatAttributionWindow () {
      const click = this.reportAttributionWindow.click?.value ?? 'N/A'
      const view = this.reportAttributionWindow.view?.value ?? 'N/A'
      return `${click} Click, ${view} View`
    },
    disableWindowUpdate () {
      const isClickDiff = this.attributionWindow.click === this.reportAttributionWindow.click?.value
      const isViewDiff = this.attributionWindow.view === this.reportAttributionWindow.view?.value
      return {
        disable: isClickDiff && isViewDiff,
        click: isClickDiff,
        view: isViewDiff
      }
    }
  },
  watch: {
    // For local report name updates
    title (newTitle) {
      this.currentRoute.name = newTitle
    },
    attributionWindow (newWindow) {
      if (this.loading) return
      const click = this.attributionWindowOptions.find((opt) => opt.value === newWindow.click)
      const view = this.viewOptions.find((opt) => opt.value === newWindow.view)
      this.reportAttributionWindow = {
        click, view
      }
    }
  },
  async mounted () {
    // Get all the reports for this lens and generate routes
    const { data } = await LensAPI.LensReport.getAll({ lens_id: this.$route.params.lensId })

    this.routes = data.map((report) => ({
      name: report.name,
      routeName: 'LensReport',
      params: { lensId: this.$route.params.lensId, reportId: report.id },
      icon: ReportIcon
    }))

    this.currentRoute = this.routes.find((route) => route.params.reportId === this.reportId)
  },
  methods: {
    updateWindow (key, val) {
      this.reportAttributionWindow[key] = val
    },
    updateLocalReport (event) {
      this.$emit('localReportUpdate', event)
      this.show.editModal = false
    },
    submitNewWindow () {
      const click = this.reportAttributionWindow.click?.value
      const view = this.reportAttributionWindow.view?.value

      if (!this.disableWindowUpdate.click) {
        this.$emit('changeWindow', { type: 'click_attribution_window', value: click })
      }
      if (!this.disableWindowUpdate.view) {
        this.$emit('changeWindow', { type: 'view_attribution_window', value: view })
      }

      this.show.attributionWindow = false
    },
    navToLens () {
      const currentLens = this.$route.params.lensId
      this.$router.push({ name: 'LensDashboardView', params: { lensId: currentLens } })
    },
    duplicateReport () {
      this.show.optionsDropdown = false
      this.$emit('showDuplicateReport')
    },
    editReport () {
      this.show.editModal = true
    },
    exportCSV () {
      // this.$showAlert({
      //   type: 'success',
      //   message: '(MOCK): export csv'
      // })
    },
    exportPDF () {
      // this.$showAlert({
      //   type: 'success',
      //   message: '(MOCK): mock pdf'
      // })
    },
    showDeleteConfirm () {
      this.show.optionsDropdown = false
      this.show.deleteModal = true
    },
    async deleteReport () {
      this.loadingDelete = true
      console.log('%c Deleting Report', 'color: pink; font-size: 14px;')
      try {
        await LensAPI.LensReport.remove(this.reportId)
      } catch (e) {
        this.$showAlert({
          type: 'error',
          message: 'Deleting report error'
        })
      } finally {
        this.loadingDelete = false
        this.$showAlert({
          type: 'success',
          message: 'Report successfully deleted'
        })
        this.$router.push({ name: 'LensView' })
      }
    },
    handleRouteChange (route) {
      // Handle route change logic here
      console.log('Route selected:', route)
    },
    getInitials (name) {
      return name.split(' ').map(word => word[0]).join('').toUpperCase().substring(0, 2)
    },
    getTimeFromNow (timestamp) {
      if (!timestamp) return 'N/A'

      const now = new Date()
      const updated = new Date(timestamp)
      const diffMs = now - updated

      // Convert to appropriate time unit
      const diffSec = Math.floor(diffMs / 1000)
      const diffMin = Math.floor(diffSec / 60)
      const diffHour = Math.floor(diffMin / 60)
      const diffDay = Math.floor(diffHour / 24)
      const diffMonth = Math.floor(diffDay / 30)
      const diffYear = Math.floor(diffMonth / 12)

      if (diffYear > 0) return `${diffYear}y`
      if (diffMonth > 0) return `${diffMonth}mo`
      if (diffDay > 0) return `${diffDay}d`
      if (diffHour > 0) return `${diffHour}h`
      if (diffMin > 0) return `${diffMin}m`
      return `${diffSec}s`
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 150ms !important;
}

.fade-enter,
.fade-leave-to .fade-leave-active {
  opacity: 0;
  transform: translateY(-1rem);
}

.fade-in {
  animation: fadeIn 150ms ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.share-report-btn:disabled{
  color: #A4ACB9;
  background-color: #F6F8FA;
  box-shadow: none;
  cursor:not-allowed
}

.base-btn {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}

.base-btn:hover, .base-btn.expanded {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.12), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}

.truncate-group-tooltip{
  background: #24252D;
  bottom: calc(-100% + 0px);
  opacity: 0;
  z-index: 99999999 !important;
  transition: all 150ms;
  pointer-events:none;

  left: 0;
  transform: translateY(0);
}

.truncate-group-trigger:hover + .truncate-group-tooltip {
  transform: translateY(-14px) !important;
  opacity: 1 !important;
}

</style>
